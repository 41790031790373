import React, {useState, useEffect, useRef } from 'react';
import "../Employer/css/PersonalDetails.scss";
import "./EditResume.scss";
import "./AppliedJobReview.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../Layout/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import PropagateLoader from "react-spinners/PropagateLoader";
import { IoArrowBackSharp } from "react-icons/io5";
import { RxPencil1 } from "react-icons/rx";
import { FiDownload } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxDotFilled } from "react-icons/rx";
import { MONTH_DETAILS } from "../data";
import { useNavigate } from "react-router-dom";
import { skillOptions } from "../data";
import { Degree } from "../data";
import Select from 'react-select';
import MonthCalculator from './MonthCalculator';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import citiesData from "../Employer/citiesData.json";


function EditResume() {

  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping1, setIsTyping1] = useState(false);
  const [pdskills, setPdskills] = useState([]);
  const navigate = useNavigate();
  const closePersonal = useRef(null);
  const closeEducation = useRef(null);
  const closeJob = useRef(null);
  const closeInternship = useRef(null);
  const closeTraining = useRef(null);
  const closeAcademics = useRef(null);
  const closeSkills = useRef(null);
  const { user } = useSelector((state) => state.user);
  const [resumeId, setResumeId] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm2, setSearchTerm2] = useState('');
  useEffect(()=>{
    const fetchMatchingCities = async () => {
      if (searchTerm.trim().length>=2) {
        try {
          const response = await axios.get(`/api/data/get-cities-data`, {
            params: { keyword: searchTerm },
          });
          if (Array.isArray(response.data)) {
            setCityOptions(response.data.map(city => ({ label: city, value: city })));
          } else {
            console.error('Unexpected response format:', response.data);
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      } else {
        setCityOptions([]);
      }
    };

    fetchMatchingCities();
  },[searchTerm])
  const noOptionsMessage = () => {
    return searchTerm.trim().length < 2 ? "Type a City Name" : "No Options";
  };
  const [isTyping, setIsTyping] = useState(false);
  const handleInputChange = (newValue) => {
    setSearchTerm(newValue);
    setIsTyping(newValue.length > 0);
  };
  // useEffect(() => {
  //   if (user && (!user.phone || !user.availability)) {
  //     toast.error("Please update your profile")
  //     navigate('/editProfileUser')
  //   }
  // },[user]);


  // useEffect(() => {
  //   async function getAllProfiles() {
  //     const response = await axios.get("/api/data/profiles");
  //     const data = await response.data;
  //     if (data) setProfiles(data);
  //   }
  //   //  async function getAllSkills() {
  //   //   const response = await axios.get("/api/data/skills");
  //   //   const data = await response.data;
  //   //   if (data) setPdskills(data);
  //   // }

  //   // getAllSkills();
  //   getAllProfiles();
  // }, []);
  useEffect(() => {
    async function getAllProfiles() {
        const response = await axios.get("/api/data/profiles");
        const data = await response.data;
        if (data) {
            setProfiles(data);
        }
    }
   
    getAllProfiles();
}, []);
  const filterOptions = (inputValue) => {
    return profiles?.filter(profile =>
        profile.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const noOptionsMessage3 = () => {
    if (!inputValue) {
      return "Type Profile";
  }
    return "No Options";
  };
  useEffect(()=>{
    async function fetchAllSkills() {
      if (searchTerm2.trim()){

        try {
          const response = await axios.get(`/api/data/skills`, {
            params: { keyword: searchTerm2 },
          });
          if (Array.isArray(response.data)) {
            setPdskills(response.data);
          } else {
            console.error('Unexpected response format:', response.data);
          }
        } catch (error) {
          console.error('Error fetching skills:', error);
        }
      }
      else{
        setPdskills([]);
      }
    }
    fetchAllSkills();
  },[searchTerm2])
  const [isTyping2, setIsTyping2] = useState(false);
  const handleInputChange2 = (newValue) => {
    setSearchTerm2(newValue);
    setIsTyping2(newValue.length > 0);
  };
  const noOptionsMessage2 = () => {
    return searchTerm2.trim().length < 2 ? "Type Skill" : "No Options";
  };
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
  };


  const pdfRef = useRef();
  const selectedCountry = "India";
  



  const downloadPDF = async() =>{
    const input = pdfRef.current;
     html2canvas(input).then((canvas)=>{
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4', true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
       pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
       pdf.save('invoice.pdf');
    })
  }


 const goBack = () => {
    window.history.back();
  };

  const calculateTotalExperience=(internships, jobs)=> {
    let totalMonths = 0;

    // Function to calculate the difference in months between two dates
    function monthDiff(d1, d2) {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    // Calculate total months for internships
    internships.forEach(internship => {
        const startDate = new Date(internship.startDate);
        const endDate = new Date(internship.endDate);
        totalMonths += monthDiff(startDate, endDate);
    });

    // Calculate total months for jobs
    jobs.forEach(job => {
        const startDate = new Date(job.startDate);
        const endDate = new Date(job.endDate);
        totalMonths += monthDiff(startDate, endDate);
    });

    // Calculate years and remaining months
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    // Construct the result string
    let result = "(";
    if (years === 1) {
        result += years + " YEAR";
    } else if (years > 1) {
        result += years + " YEARS";
    }

    if (months === 1) {
        result += " " + months + " MONTH";
    } else if (months > 1) {
        result += " " + months + " MONTHS";
    }
    result += ")";

    return result;
}
  

  
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [resumeData, setResumeData] = useState("")
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {

    window.scrollTo(0, 0)
  },[]);

  // HOOKS FOR RESUME COMPONENTS:

  const [skillVissible, setSkillVissible] = useState(false);

  const [personalDetails, setPersonalDetails] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    profile: "",
    availability:""
  });

  const [education, setEducation] = useState({
    _id: "",
    school: "",
    degree: "",
    startDate: "",
    endDate: "",
    performanceScale: "",
    performance: "",
    specialization: "",
  });

  const [job, setJob] = useState({
    _id: "",
    profile: "",
    organization: "",
    location: "",
    startDate: "",
    endDate: "",
    description: "",
  });

  const [internship, setInternship] = useState({
    _id: "",
    profile: "",
    organization: "",
    location: "",
    startDate: "",
    endDate: "",
    description: "",
  });

  const [training, setTraining] = useState({
    _id: "",
    program: "",
    organization: "",
    location: "",
    startDate: "",
    endDate: "",
    description: "",
  });

  const [academic, setAcademic] = useState({
    _id: "",
    title: "",
    startDate: "",
    endDate: "",
    description: "",
    link: "",
  });

  const [skill, setskill] = useState({
    _id: "",
    name: "",
    level: "Beginner",
  });






  // HANDLE INPUT FUNCTIONS FOR RESUME COMPONENTS:

  const handlPersonalDetailsChange = (e) => {
    setPersonalDetails({
      ...personalDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleEducationChange = (e) => {
    setEducation({
      ...education,
      [e.target.name]: e.target.value,
    });
  };

  const handleJobChange = (e) => {
    setJob({
      ...job,
      [e.target.name]: e.target.value,
    });
  };

  const handleInternshipChange = (e) => {
    setInternship({
      ...internship,
      [e.target.name]: e.target.value,
    });
  };

  const handleTrainingChange = (e) => {
    setTraining({
      ...training,
      [e.target.name]: e.target.value,
    });
  };

  const handleAcademicChange = (e) => {
    setAcademic({
      ...academic,
      [e.target.name]: e.target.value,
    });
  };

  const handleSkillChange = (e) => {
    setskill({
      ...skill,
      [e.target.name]: e.target.value,
    });
  };






  // ADD FUNCTION FOR RESUME COMPONENTS: 

  const updatePersonalDetails = async () => {
    setLoading(true)
    if (personalDetails.firstName == "") {
      setLoading(false);
      toast.error("Enter first name")
    }
    else if (personalDetails.lastName == "") {
      setLoading(false);
      toast.error("Enter last name")
    }
    else if (personalDetails.phone == "") {
      setLoading(false);
      toast.error("Enter phone number")
    }
    else if (personalDetails.phone.length < 7 || personalDetails.phone.length > 16) {
      setLoading(false);
      toast.error("Number must be between 7 & 16 digits");
    }
    else if (personalDetails.profile == "") {
      setLoading(false);
      toast.error("Enter your profile")
    }
    else if (personalDetails.address == "") {
      setLoading(false);
      toast.error("Enter your address")
    }
    else if (personalDetails.availability === "" || !personalDetails.availability){
      setLoading(false);
      toast.error("Select availability")
    }
    else {

      try {
        const { data } = await axios.post("/api/user/createResume/PersonalDetails", {
          firstName: personalDetails.firstName,
          lastName: personalDetails.lastName,
          email: personalDetails.email,
          phone: personalDetails.phone,
          address: personalDetails.address,
          profile: personalDetails.profile,
          availability: personalDetails.availability
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closePersonal.current.click();
        toast.success("Details Added Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
         fetchResumeId();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const updateEducation = async () => {
    setLoading(true)
    if (education.school == "") {
      setLoading(false);
      toast.error("Enter school/college name")
    }
    else if (education.degree == "") {
      setLoading(false);
      toast.error("Enter degree name")
    }
    else if (education.specialization == "") {
      setLoading(false);
      toast.error("Enter course name")
    }
    else if (education.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if (education.endDate == "" ) {
      setLoading(false);
      toast.error("Enter end date")
  } else if (new Date(education.endDate && education.startDate) > new Date(education.endDate)) {
    setLoading(false);
    toast.error("Start date cannot be greater than end date");
  }
    else if (education.performanceScale == "") {
      setLoading(false);
      toast.error("Enter performanceScale")
    }
    else if (education.performance == "") {
      setLoading(false);
      toast.error("Enter performance")
    }
    else {

      try {
        const { data } = await axios.post("/api/user/createResume/Education", {
          school: education.school,
          degree: education.degree,
          startDate: education.startDate,
          endDate: education.endDate,
          performanceScale: education.performanceScale,
          performance: education.performance,
          specialization: education.specialization
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeEducation.current.click();
        toast.success("Details Added Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }


    }

  }

  const updateJob = async () => {
    setLoading(true);
    if (job.profile == "") {
      setLoading(false);
      toast.error("Enter profile name")
    }
    else if (job.organization == "") {
      setLoading(false);
      toast.error("Enter organization name")
    }
    else if (job.location == "") {
      setLoading(false);
      toast.error("Enter location")
    }
    else if (job.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if (job.endDate == "") {
      setLoading(false);
      toast.error("Enter end date")
    }
    else if (new Date(job.startDate) > new Date(job.endDate)) {
      setLoading(false);
      toast.error("Start date cannot be greater than end date");
    }
    else if (job.description == "") {
      setLoading(false);
      toast.error("Enter description")
    }
    else {
      try {
        const { data } = await axios.post("/api/user/createResume/Job", {
          profile: job.profile,
          organization: job.organization,
          location: job.location,
          startDate: job.startDate,
          endDate: job.endDate,
          description: job.description,
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeJob.current.click();
        toast.success("Details Added Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const updateInternship = async () => {
    setLoading(true);
    if (internship.profile == "") {
      setLoading(false);
      toast.error("Enter profile name")
    }
    else if (internship.organization == "") {
      setLoading(false);
      toast.error("Enter organization name")
    }
    else if (internship.location == "") {
      setLoading(false);
      toast.error("Enter location")
    }
    else if (internship.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if (internship.endDate == "") {
      setLoading(false);
      toast.error("Enter end date")
    }
    else if (new Date(internship.startDate) > new Date(internship.endDate)) {
      setLoading(false);
      toast.error("Start date cannot be greater than end date");
    }
    else if (internship.description == "") {
      setLoading(false);
      toast.error("Enter description")
    }
    else {
      try {
        const { data } = await axios.post("/api/user/createResume/Internship", {
          profile: internship.profile,
          organization: internship.organization,
          location: internship.location,
          startDate: internship.startDate,
          endDate: internship.endDate,
          description: internship.description,
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeInternship.current.click();
        toast.success("Details Added Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const updateTraining = async () => {
    setLoading(true);
    if (training.program == "") {
      setLoading(false);
      toast.error("Enter program name")
    }
    else if (training.organization == "") {
      setLoading(false);
      toast.error("Enter organization name")
    }
    else if (training.location == "") {
      setLoading(false);
      toast.error("Enter location")
    }
    else if (training.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if (training.endDate == "") {
      setLoading(false);
      toast.error("Enter end date")
    }
    else if (new Date(training.startDate) > new Date(training.endDate)) {
      setLoading(false);
      toast.error("Start date cannot be greater than end date");
    }
    else if (training.description == "") {
      setLoading(false);
      toast.error("Enter description")
    }
    else {
      try {
        const { data } = await axios.post("/api/user/createResume/Training", {
          program: training.program,
          organization: training.organization,
          location: training.location,
          startDate: training.startDate,
          endDate: training.endDate,
          description: training.description,
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeTraining.current.click();
        toast.success("Details Added Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const updateAcademic = async () => {
    setLoading(true);
    if (academic.title == "") {
      setLoading(false);
      toast.error("Enter project title")
    }
    else if (academic.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if (academic.endDate == "") {
      setLoading(false);
      toast.error("Enter end date")
    }
    else if (new Date(academic.startDate) > new Date(academic.endDate)) {
      setLoading(false);
      toast.error("Start date cannot be greater than end date");
    }
    else if (academic.description == "") {
      setLoading(false);
      toast.error("Enter description")
    }
    else if (academic.link == "") {
      setLoading(false);
      toast.error("Enter project link")
    }
    else {
      try {
        const { data } = await axios.post("/api/user/createResume/Academics", {
          title: academic.title,
          startDate: academic.startDate,
          endDate: academic.endDate,
          description: academic.description,
          link: academic.link,
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeAcademics.current.click();
        toast.success("Details Added Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const updateSkill = async () => {
    setLoading(true);
    if (skill.name === "") {
      setLoading(false);
      toast.error("Enter skill name");
    } else {
      // Check if skill name already exists in resumeData.Skills
      const skillExists = resumeData.Skills.some(
        (existingSkill) => existingSkill.name === skill.name
      );
      
      if (skillExists) {
        setLoading(false);
        toast.error("This skill is already added");
      } else {
        try {
          const { data } = await axios.post("/api/user/createResume/Skills", {
            name: skill.name,
            level: skill.level,
          }, {
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
          });
          setLoading(false);
          closeSkills.current.click();
          toast.success("Details Added Successfully", {
            style: {
              padding: '18px',
            }
          });
          getResumeData2();
        } catch (error) {
          setLoading(false);
          alert(error);
        }
      }
    }
  };
  

  // UPDATE FUCTIONS FOR RESUME COMPONENTS:

  const modifyPersonalDetails = async () => {
    setLoading(true)
    if (personalDetails.firstName == "") {
      setLoading(false);
      toast.error("Enter first name")
    }
    else if (personalDetails.lastName == "") {
      setLoading(false);
      toast.error("Enter last name")
    }
    else if (personalDetails.profile == "") {
      setLoading(false);
      toast.error("Enter your profile")
    }
    else if (personalDetails.address == "") {
      setLoading(false);
      toast.error("Enter your address")
    }
    else if (personalDetails.phone == "") {
      setLoading(false);
      toast.error("Enter phone number")
    }
    else if (personalDetails.phone.length < 7 || personalDetails.phone.length > 16) {
      setLoading(false);
      toast.error("Number must be between 7 & 16 digits");
    }
    else if (personalDetails.availability === "" || !personalDetails.availability) {
      setLoading(false);
      toast.error("Select availability")
    }
    
    else {

      try {
        const { data } = await axios.put(`/api/user/updateResume/PersonalDetails/${personalDetails._id}`, {
          firstName: personalDetails.firstName,
          lastName: personalDetails.lastName,
          email: personalDetails.email,
          phone: personalDetails.phone,
          address: personalDetails.address,
          profile: personalDetails.profile,
          availability: personalDetails.availability
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closePersonal.current.click();
        toast.success("Details Updated Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const modifyEducation = async () => {
    setLoading(true)
    if (education.school == "") {
      setLoading(false);
      toast.error("Enter school/college name")
    }
    else if (education.degree == "") {
      setLoading(false);
      toast.error("Enter degree name")
    }
    else if (education.specialization == "") {
      setLoading(false);
      toast.error("Enter course name")
    }
    else if (education.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if ( education.endDate == "") {
      setLoading(false);
      toast.error("Enter end date")
    }
    else if (education.endDate && new Date(education.startDate) > new Date(education.endDate)) {
      setLoading(false);
      toast.error("Start date cannot be greater than end date");
    }
    else if (education.performanceScale == "") {
      setLoading(false);
      toast.error("Enter performanceScale")
    }
    else if (education.performance == "") {
      setLoading(false);
      toast.error("Enter performance")
    }
    else {

      try {
        const { data } = await axios.put(`/api/user/updateResume/Education/${education._id}`, {
          school: education.school,
          degree: education.degree,
          startDate: education.startDate,
          endDate: education.endDate,
          performanceScale: education.performanceScale,
          performance: education.performance,
          specialization: education.specialization,
         
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeEducation.current.click();
        toast.success("Details Updated Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const modifyJob = async () => {
    setLoading(true);
    if (job.profile == "") {
      setLoading(false);
      toast.error("Enter profile name")
    }
    else if (job.organization == "") {
      setLoading(false);
      toast.error("Enter organization name")
    }
    else if (job.location == "") {
      setLoading(false);
      toast.error("Enter location")
    }
    else if (job.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if (job.endDate == "") {
      setLoading(false);
      toast.error("Enter end date")
    }
    else if (new Date(job.startDate) > new Date(job.endDate)) {
      setLoading(false);
      toast.error("Start date cannot be greater than end date");
    }
    else if (job.description == "") {
      setLoading(false);
      toast.error("Enter description")
    }
    else {
      try {
        const { data } = await axios.put(`/api/user/updateResume/Job/${job._id}`, {
          profile: job.profile,
          organization: job.organization,
          location: job.location,
          startDate: job.startDate,
          endDate: job.endDate,
          description: job.description,
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeJob.current.click();
        toast.success("Details Updated Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const modifyInternship = async () => {
    setLoading(true);
    if (internship.profile == "") {
      setLoading(false);
      toast.error("Enter profile name")
    }
    else if (internship.organization == "") {
      setLoading(false);
      toast.error("Enter organization name")
    }
    else if (internship.location == "") {
      setLoading(false);
      toast.error("Enter location")
    }
    else if (internship.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if (internship.endDate == "") {
      setLoading(false);
      toast.error("Enter end date")
    }
    else if (new Date(internship.startDate) > new Date(internship.endDate)) {
      setLoading(false);
      toast.error("Start date cannot be greater than end date");
    }
    else if (internship.description == "") {
      setLoading(false);
      toast.error("Enter description")
    }
    else {
      try {
        const { data } = await axios.put(`/api/user/updateResume/Internship/${internship._id}`, {
          profile: internship.profile,
          organization: internship.organization,
          location: internship.location,
          startDate: internship.startDate,
          endDate: internship.endDate,
          description: internship.description,
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeInternship.current.click();
        toast.success("Details Updated Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const modifyTraining = async () => {
    setLoading(true);
    if (training.program == "") {
      setLoading(false);
      toast.error("Enter program name")
    }
    else if (training.organization == "") {
      setLoading(false);
      toast.error("Enter organization name")
    }
    else if (training.location == "") {
      setLoading(false);
      toast.error("Enter location")
    }
    else if (training.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if (training.endDate == "") {
      setLoading(false);
      toast.error("Enter end date")
    }
    else if (new Date(training.startDate) > new Date(training.endDate)) {
      setLoading(false);
      toast.error("Start date cannot be greater than end date");
    }
    else if (training.description == "") {
      setLoading(false);
      toast.error("Enter description")
    }
    else {
      try {
        const { data } = await axios.put(`/api/user/updateResume/Training/${training._id}`, {
          program: training.program,
          organization: training.organization,
          location: training.location,
          startDate: training.startDate,
          endDate: training.endDate,
          description: training.description,
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeTraining.current.click();
        toast.success("Details Updated Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const modifyAcademic = async () => {
    setLoading(true);
    if (academic.title == "") {
      setLoading(false);
      toast.error("Enter project title")
    }
    else if (academic.startDate == "") {
      setLoading(false);
      toast.error("Enter start date")
    }
    else if (academic.endDate == "") {
      setLoading(false);
      toast.error("Enter end date")
    }
    else if (new Date(academic.startDate) > new Date(academic.endDate)) {
      setLoading(false);
      toast.error("Start date cannot be greater than end date");
    }
    else if (academic.description == "") {
      setLoading(false);
      toast.error("Enter description")
    }
    else if (academic.link == "") {
      setLoading(false);
      toast.error("Enter project link")
    }
    else {
      try {
        const { data } = await axios.put(`/api/user/updateResume/Academics/${academic._id}`, {
          title: academic.title,
          startDate: academic.startDate,
          endDate: academic.endDate,
          description: academic.description,
          link: academic.link,
        }, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        setLoading(false);
        closeAcademics.current.click();
        toast.success("Details Updated Successfully", {
            style: {
            padding: '18px',
          }
        });
         getResumeData2();
      } catch (error) {
        setLoading(false);
        alert(error)
      }
    }
  }


  const modifySkill = async () => {
    setLoading(true);
    if (skill.name === "") {
      setLoading(false);
      toast.error("Enter skill name");
    } else {
      // Check if skill name already exists in resumeData.Skills
      const skillExists = resumeData.Skills.some(
        (existingSkill) => existingSkill.name === skill.name
      );
      
      if (skillExists) {
        setLoading(false);
        toast.error("This skill is already added");
      } else {
        try {
          const { data } = await axios.put(`/api/user/updateResume/Skills/${skill._id}`, {
            name: skill.name,
            level: skill.level,
          }, {
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
          });
          setLoading(false);
          closeSkills.current.click();
          toast.success("Details Updated Successfully", {
            style: {
              padding: '18px',
            }
          });
          getResumeData2();
        } catch (error) {
          setLoading(false);
          alert(error);
        }
      }
    }
  };
  



  // DELETE FUNCTION FOR RESUME COMPONENTS: 

  const deleteEducation = async (_id) => {
    try {
      const { data } = await axios.delete(`/api/user/deleteResume/Education/${_id}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      toast.success("Details Deleted Successfully", {
        style: {
          padding: '18px',
        }
      });
      getResumeData2();
    } catch (error) {

    }
  }


  const deleteJob = async (_id) => {
    try {
      const { data } = await axios.delete(`/api/user/deleteResume/Job/${_id}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      toast.success("Details Deleted Successfully", {
        style: {
          padding: '18px',
        }
      });
      getResumeData2();
    } catch (error) {

    }
  }


  const deleteInternship = async (_id) => {
    try {
      const { data } = await axios.delete(`/api/user/deleteResume/Internship/${_id}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      toast.success("Details Deleted Successfully", {
        style: {
          padding: '18px',
        }
      });
      getResumeData2();
    } catch (error) {

    }
  }


  const deleteTraining = async (_id) => {
    try {
      const { data } = await axios.delete(`/api/user/deleteResume/Training/${_id}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      toast.success("Details Deleted Successfully", {
        style: {
          padding: '18px',
        }
      });
      getResumeData2();
    } catch (error) {

    }
  }

  const deleteAcademics = async (_id) => {
    try {
      const { data } = await axios.delete(`/api/user/deleteResume/Academics/${_id}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      toast.success("Details Deleted Successfully", {
        style: {
          padding: '18px',
        }
      });
      getResumeData2();
    } catch (error) {

    }
  }


  const deleteSkills = async (_id) => {
    try {
      const { data } = await axios.delete(`/api/user/deleteResume/Skills/${_id}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      toast.success("Details Deleted Successfully", {
        style: {
          padding: '18px',
        }
      });
      getResumeData2();
    } catch (error) {

    }
  }





  // EDIT FUNCTION FOR RESUME COMPONENTS: 


  const editPersonalDetails = (data) => {
    setPersonalDetails({
      ...personalDetails,
      _id: data._id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      address: data.address,
      profile: data.profile,
      availability: data.availability,
    });
  }

  const editEducation = (data) => {
    setEducation({
      ...education,
      _id: data._id,
      school: data.school,
      degree: data.degree,
      startDate: data.startDate,
      endDate: data.endDate,
      performanceScale: data.performanceScale,
      performance: data.performance,
      specialization: data.specialization

    });
  }

  const addEducation = () => {
    setEducation({
      ...education,
      _id: "",
      school: "",
      degree: "",
      startDate: "",
      endDate: "",
      performanceScale: "",
      performance: "",
      specialization: "",

    });
  }



  const editJob = (data) => {
    setJob({
      ...job,
      _id: data._id,
      profile: data.profile,
      organization: data.organization,
      location: data.location,
      startDate: data.startDate,
      endDate: data.endDate,
      description: data.description,
    });
  }

  const addJob = () => {
    setJob({
      ...job,
      _id: "",
      profile: "",
      organization: "",
      location: "",
      startDate: "",
      endDate: "",
      description: "",
    });
  }



  const editInternship = (data) => {
    setInternship({
      ...internship,
      _id: data._id,
      profile: data.profile,
      organization: data.organization,
      location: data.location,
      startDate: data.startDate,
      endDate: data.endDate,
      description: data.description,
    });
  }

  const addInternship = () => {
    setInternship({
      ...internship,
      _id: "",
      profile: "",
      organization: "",
      location: "",
      startDate: "",
      endDate: "",
      description: "",
    });
  }



  const editTraining = (data) => {
    setTraining({
      ...training,
      _id: data._id,
      program: data.program,
      organization: data.organization,
      location: data.location,
      startDate: data.startDate,
      endDate: data.endDate,
      description: data.description,
    });
  }

  const addTraining = () => {
    setTraining({
      ...training,
      _id: "",
      program: "",
      organization: "",
      location: "",
      startDate: "",
      endDate: "",
      description: "",
    });
  }



  const editAcademics = (data) => {
    setAcademic({
      ...academic,
      _id: data._id,
      title: data.title,
      startDate: data.startDate,
      endDate: data.endDate,
      description: data.description,
      link: data.link,
    });
  }

  const addAcademics = () => {
    setAcademic({
      ...academic,
      _id: "",
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      link: "",
    });
  }



  const editSkill = (data) => {
    setskill({
      ...skill,
      _id: data._id,
      name: data.name,
      level: data.level,
    });
  }

  const addSkill = () => {
    setskill({
      ...skill,
      _id: "",
      name: "",
      level: "Beginner",
    });
  }



  // GETTING RESUME DATA:

  const getResumeData = async () => {
    setLoader(true);
    try{
    const { data } = await axios.get("/api/user/getResume", {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });
    setResumeData(data.resume);
    setLoader(false);
  }
  catch{
    toast.error("Something went wrong");
    setLoader(false);
  }
  }


  const getResumeData2 = async () => {
    try{
    const { data } = await axios.get("/api/user/getResume", {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });
    setResumeData(data.resume);
  }
  catch{
    toast.error("Something went wrong");
  }
  }
  // SEPRATOR:

  const seprator = (str) => {
    const arr = str.split(" ")
    return arr;
  }






  // USEEFFECT:

  useEffect(() => {
    if (user) {
      getResumeData();
    }

    setPersonalDetails({
      ...personalDetails,
      firstName: user && seprator(user.name)[0],
      lastName: user && seprator(user.name)[1],
      email: user && user.email,
      // phone: user && user.phone,
      // address: user && user.city,
      availability: user && user.availability
    });

  }, [user]);

  const fetchResumeId = async () => {
    try {
      const response = await axios.get('/api/user/getResumeIdPersonal', {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (response.data.success) {
        setResumeId(response.data.resumeId);
      }
    } catch (error) {
      console.error('Error fetching resume ID:', error);
    }
  };
  
  useEffect(() => {
    // You can add additional logic here if needed
  }, [education]); // Add other dependencies as needed
  
  console.log("data",resumeData);
  return (
    <>
<div className="editResumeBg pt-2" style={{backgroundcolor: 'white'}}>

      <p onClick={goBack} className="ResumeBackBtn"><IoArrowBackSharp style={{marginRight: "8px", fontSize:"17px"}} />Back</p>

    
      {loader? (<>
              
              <div className=""  style={{ textAlign: 'center', paddingBottom: "220px" }}>
                      <p style={{ color: "#4B92C8", fontWeight: "500", fontSize: "20px", marginLeft: '43px', marginBottom: "5px", marginTop: "220px" }}>Loading..</p>
                      <PropagateLoader
                          color={'#4B92C8'}
                          loading={true}
                          size={15}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          style={{marginLeft: "27px"}}
                      />
                  </div>
    

        </>) : (<>
      <div className='text-center mt-4 mb-2' >
        <p style={{fontSize: "30px", fontWeight: "500"}}>Resume</p>
      </div>



      <div className=' container pb-5 px-5' style={{ padding: "0px", margin: "auto" }}>
      <div ref={pdfRef} className="InvoiceDownload section31 my-4 " style={{letterSpacing: "0.3px"}}>
        
          <div class="card-body">

            <div class="container" >
              {resumeData && resumeData.PersonalDetails ? (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <p style={{fontSize: "29px", fontWeight: "500"}}>{resumeData.PersonalDetails.firstName} {resumeData.PersonalDetails.lastName}
                    <RxPencil1 className= "mx-2 resumePeronalDetailpencilicon" style={{ color: "gray", cursor: 'pointer', fontSize: "25px", marginTop: '-3px' }} onClick={() => { editPersonalDetails(resumeData.PersonalDetails) }} aria-hidden="true" data-bs-toggle="modal" data-bs-target="#personalDetails" /></p>
                  { /* <span style={{fontSize: "15px",  cursor: 'pointer', color: "#2092c7" }}    onClick={downloadPDF}  className="aplied2 float-right">
                  <FiDownload style={{ fontSize: "20px", marginTop: '-3px'}}/> Download
                 </span>*/}
                  </div>

                  <div style={{ color: 'gray' }}>
                    <p style={{ marginTop: '-6px', fontSize: "15px", color: "#6E6E6E" }}>{resumeData.PersonalDetails.email}</p>
                    <p style={{ marginTop: '-6px', fontSize: "15px", color: "#6E6E6E" }}>+91 {resumeData.PersonalDetails.phone}</p>
                    <p style={{ marginTop: '-6px', fontSize: "15px", color: "#6E6E6E" }}>{resumeData.PersonalDetails.address}</p>
                    <p style={{ marginTop: '-6px', fontSize: "15px", color: "#6E6E6E" }}>{resumeData.PersonalDetails.availability}</p>
                  </div>
                </>
              ) : (
                <>
                  <div class="row">
                    <div class="col-3" style= {{minWidth: "150px"}}>
                      
                      <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500" }}>PERSONAL DETAILS</p>
                    </div>
                    <div class="col-7">
                      <p class="btn p-0 " data-bs-toggle="modal" data-bs-target="#personalDetails" style={{ color: "#0196C5", fontSize: '15px' }}>+ Add Personal Details</p>
                    </div>
                    <div class="col-2">
                    </div>
                  </div>
                </>
              )}
            </div>



            <hr className="dropdown-divider1 mb-4 mt-5"  style={{height: '0.05px', color: "rgb(185, 185, 185)"}} />


            <div class="container"
              style={{ padding: "25px" }}
            >
              <div class="row">
                <div class="col-3" style= {{minWidth: "150px"}}>
                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500" }}>EDUCATION</p>
                </div>
                <div class="col-9 mobile-column-to-increase-width" >

                  {resumeData && resumeData.Education ? (<>
                    {resumeData.Education.map((data) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-10 col-7" >
                            <div style={{ marginBottom: "30px", color: "#4A4E50" }}>
                              <p style={{fontSize: "15px", color: "#404040"}}><b>{data.degree}</b></p>
                              <div style={{ color: '#6E6E6E', fontSize: '15px', marginTop: '-8px' }}>
                                <p>{data.school}</p>
                                {data.specialization? (<p style={{ marginTop: "-8px" }}>({data.specialization})</p>): "" }
                                <p style={{ marginTop: "-8px" }}>{(data.startDate).slice(0, 4)} - {(data.endDate).slice(0, 4)}</p>
                                <p style={{ marginTop: "-8px" }}>{data.performanceScale}: {data.performance}{data.performanceScale==="Percentage"&&"%"}</p>
                               
                              </div>
                            </div>
                            </div>
                            <div className="col-md-2 col-5" >
                            <div className="mobile-flex-container">
                              <RxPencil1 class=" mr-4" style={{ color: "#646464", fontSize: '23px', cursor: "pointer" }} onClick={() => { editEducation(data) }} aria-hidden="true" data-bs-toggle="modal" data-bs-target="#education" />
                              <RiDeleteBinLine className="ml-2" style={{ color: "#646464", fontSize: "21px", cursor: "pointer", marginTop: '-2px' }} aria-hidden="true" onClick={() => { deleteEducation(data._id) }} />
                            </div>
                            </div>
                          </div>
                        </>
                      )
                    })
                    }
                  </>) : (<></>)}
                  {resumeData && resumeData.Education && resumeData.Education.length >= 4 ? (<></>) : (<>

                    {user && (user.resume || resumeId) ? (<>
                      <p class="btn p-0 " onClick={() => { addEducation() }} data-bs-toggle="modal" data-bs-target="#education" style={{ color: "#0196C5", fontSize: '15px' }} >+ Add education</p>
                    </>) : (<>
                      <p class="btn p-0 " onClick={() => { toast.error("Add personal details first!!") }} style={{ color: "#0196C5", fontSize: '15px' }} >+ Add education</p>
                    </>)}

                  </>)}


                </div>

              </div>
            </div>
            <hr className="dropdown-divider1 mb-4 mt-3"  style={{height: '0.05px', color: "rgb(185, 185, 185)"}} />



            <div class="container"
              style={{ padding: "25px" }}
            >
              <div class="row">
                <div class="col-3" style= {{minWidth: "150px"}}>
                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500", lineHeight: "16px" }}>WORK<br/> EXPERIENCE<br/>{resumeData && (resumeData.Job || resumeData.Internship) ? calculateTotalExperience(resumeData.Job,resumeData.Internship): <></>}</p>
                </div>
                <div class="col-9 mobile-column-to-increase-width">

                  {resumeData && resumeData.Job ? (<>
                  
                    {resumeData.Job.map((data) => {
                      return (
                        <>
                          <div className="row">
                            {/* <div className="col-10"> */}
                            <div className="col-md-10 col-7">
                            <div style={{ marginBottom: "30px" }}>
                              <p style={{fontSize: "15px", color: "#404040"}}><b>{data.profile} </b></p>
                              <div style={{ color: '#6E6E6E', fontSize: '15px', marginTop: '-8px' }}>
                              <p style={{ marginTop: "-8px" }}>{data.organization}, {data.location}</p>
                                <p style={{ marginTop: "-8px" }}>Job <RxDotFilled style={{color: "#464545"}} /> { MONTH_DETAILS[parseInt((data.startDate).slice(5, 7) , 10)] + " " + (data.startDate).slice(0, 4)} - {MONTH_DETAILS[parseInt((data.endDate).slice(5, 7) , 10)] + " " + (data.endDate).slice(0, 4)} {" "}{ <MonthCalculator startDate={data.startDate} endDate={data.endDate} />}</p>
                                <p style={{ marginTop: "-8px" }}>{data.description}</p>
                              </div>
                            </div>
                            </div>
                            {/* <div className="col-2" > */}
                            <div className="col-md-2 col-5" >
                            <div className="mobile-flex-container">
                              <RxPencil1 class=" mr-4" style={{ color: "#646464", fontSize: '23px', cursor: "pointer" }} onClick={() => { editJob(data) }} aria-hidden="true" data-bs-toggle="modal" data-bs-target="#job" />
                              <RiDeleteBinLine className="ml-2" style={{ color: "#646464", fontSize: "21px", cursor: "pointer", marginTop: '-2px' }} aria-hidden="true" onClick={() => { deleteJob(data._id) }} />
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })
                    }
                  </>) : (<></>)}
                  {resumeData && resumeData.Internship ? (<>
                  
                  {resumeData.Internship.map((data) => {
                    return (
                      <>
                        <div className="row">
                          {/* <div className="col-10"> */}
                          <div className="col-md-10 col-7">
                          <div style={{ marginBottom: "30px", color: "#2C2C2C" }}>
                             <p style={{fontSize: "15px", color: "#404040"}}><b>{data.profile} </b></p>
                              <div style={{ color: '#6E6E6E', fontSize: '15px', marginTop: '-8px' }}>
                              <p style={{ marginTop: "-8px" }}>{data.organization}, {data.location}</p>
                                <p style={{ marginTop: "-8px" }}>Internship <RxDotFilled style={{color: "#464545"}} /> { MONTH_DETAILS[parseInt((data.startDate).slice(5, 7) , 10)] + " " + (data.startDate).slice(0, 4)} - {MONTH_DETAILS[parseInt((data.endDate).slice(5, 7) , 10)] + " " + (data.endDate).slice(0, 4)} {" "}{ <MonthCalculator startDate={data.startDate} endDate={data.endDate} />}</p>
                                <p style={{ marginTop: "-8px" }}>{data.description}</p>
                              </div>
                            </div> 
                          </div>
                          {/* <div className="col-2" > */}
                          <div className="col-md-2 col-5" >
                          <div className="mobile-flex-container">
                            <RxPencil1 class=" mr-4" style={{ color: "#646464", fontSize: '23px', cursor: "pointer" }} onClick={() => { editInternship(data) }} aria-hidden="true" data-bs-toggle="modal" data-bs-target="#internship" />
                            <RiDeleteBinLine className="ml-2" style={{ color: "#646464", fontSize: "21px", cursor: "pointer", marginTop: '-2px' }} aria-hidden="true" onClick={() => { deleteInternship(data._id) }} />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })
                  }
                </>) : (<></>)}
                <div style={{display: "flex", flexWrap: "wrap", gap: "30px"}}>
                  {resumeData && resumeData.Job && resumeData.Job.length >= 3 ? (<></>) : (<>
                    {user && (user.resume || resumeId) ? (<>
                      <p class="btn p-0 " onClick={() => { addJob() }} data-bs-toggle="modal" data-bs-target="#job" style={{ color: "#0196C5", fontSize: '15px' }} >+ Add job</p>
                    </>) : (<>
                      <p class="btn p-0 " onClick={() => { toast.error("Add personal details first!!") }} style={{ color: "#0196C5", fontSize: '15px' }} >+ Add job</p>
                    </>)}
                  </>)}
                  {resumeData && resumeData.Internship && resumeData.Internship.length >= 3 ? (<></>) : (<>
                    {user && (user.resume || resumeId) ? (<>
                      <p class="btn p-0 " onClick={() => { addInternship() }} data-bs-toggle="modal" data-bs-target="#internship" style={{ color: "#0196C5", fontSize: '15px' }} >+ Add internship</p>
                    </>) : (<>
                      <p class="btn p-0 " onClick={() => { toast.error("Add personal details first!!") }} style={{ color: "#0196C5", fontSize: '15px' }} >+ Add internship</p>
                    </>)}
                  </>)}
                  </div>

                </div>

              </div>
            </div>



          

          <hr className="dropdown-divider1 mb-4 mt-3"  style={{height: '0.05px', color: "rgb(185, 185, 185)"}} />



            <div class="container"
              style={{ padding: "25px" }}
            >
              <div class="row">
                <div class="col-3" style= {{minWidth: "150px"}}>
                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500", lineHeight: "16px" }}>TRAININGS/<br/>COURSES</p>
                </div>
                <div class="col-9 mobile-column-to-increase-width">

                  {resumeData && resumeData.Training ? (<>
                    {resumeData.Training.map((data) => {
                      return (
                        <>
                          <div className="row">
                            {/* <div className="col-10"> */}
                            <div className="col-md-10 col-7">
                            <div style={{ marginBottom: "30px" }}>
                              <p style={{fontSize: "15px", color: "#404040"}}><b>{data.program}</b></p>
                              <div style={{ color: '#6E6E6E', fontSize: '15px', marginTop: '-8px' }}>
                                <p style={{ marginTop: "-8px" }}>{data.organization}, {data.location}</p>
                                <p style={{ marginTop: "-8px" }}>{ MONTH_DETAILS[parseInt((data.startDate).slice(5, 7) , 10)] + " " + (data.startDate).slice(0, 4)} - {MONTH_DETAILS[parseInt((data.endDate).slice(5, 7) , 10)] + " " + (data.endDate).slice(0, 4)}</p>
                                <p style={{ marginTop: "-8px" }}>{data.description}</p>
                              </div>
                            </div> 
                            </div>
                            {/* <div className="col-2 " > */}
                            <div className="col-md-2 col-5" >
                            <div className="mobile-flex-container">
                            <RxPencil1 class=" mr-4" style={{ color: "#646464", fontSize: '23px', cursor: "pointer" }} onClick={() => { editTraining(data) }} aria-hidden="true" data-bs-toggle="modal" data-bs-target="#training" />
                            <RiDeleteBinLine className="ml-2" style={{ color: "#646464", fontSize: "21px", cursor: "pointer", marginTop: '-2px' }} aria-hidden="true" onClick={() => { deleteTraining(data._id) }} />
                            </div>
                            </div>
                          </div>
                        </>
                      )
                    })
                    }
                  </>) : (<></>)}

                  {resumeData && resumeData.Training && resumeData.Training.length >= 2 ? (<></>) : (<>
                    {user && (user.resume || resumeId) ? (<>
                      <p class="btn p-0 " onClick={() => { addTraining() }} data-bs-toggle="modal" data-bs-target="#training" style={{ color: "#0196C5", fontSize: '15px' }} >+ Add training / courses</p>
                    </>) : (<>
                      <p class="btn p-0 " onClick={() => { toast.error("Add personal details first!!") }} style={{ color: "#0196C5", fontSize: '15px' }} >+ Add training / courses</p>
                    </>)}
                  </>)}



                </div>

              </div>
            </div>
            <hr className="dropdown-divider1 mb-4 mt-3"  style={{height: '0.05px', color: "rgb(185, 185, 185)"}} />


            <div class="container"
              style={{ padding: "25px" }}
            >
              <div class="row">
                <div class="col-3" style= {{minWidth: "150px"}}>
                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500", lineHeight: "16px" }}>ACADEMICS/<br/> PERSONAL PROJECTS</p>
              </div>
                <div class="col-9 mobile-column-to-increase-width">
                  {resumeData && resumeData.Academics ? (<>
                    {resumeData.Academics.map((data) => {
                      return (
                        <>
                          <div className="row">
                            {/* <div className="col-10"> */}
                            <div className="col-md-10 col-7">
                            <div style={{ marginBottom: "30px", color: "#2C2C2C" }}>
                              <p style={{fontSize: "15px", color: "#404040"}}><b>{data.title}</b></p>
                              <div style={{ color: '#6E6E6E', fontSize: '15px', marginTop: '-8px' }}>
                               <p style={{ marginTop: "-8px" }}>{ MONTH_DETAILS[parseInt((data.startDate).slice(5, 7) , 10)] + " " + (data.startDate).slice(0, 4)} - {MONTH_DETAILS[parseInt((data.endDate).slice(5, 7) , 10)] + " " + (data.endDate).slice(0, 4)}</p>
                               <a 
  target="_gray" 
  href={data.link} 
  style={{ 
    color: "#0885B7", 
    display: "inline-block", 
    maxWidth: "100%", 
    overflow: "hidden", 
    textOverflow: "ellipsis", 
    textDecoration: "none", 
    whiteSpace: "nowrap" 
  }}
>
  {data.link}
</a>
                                <p style={{ marginTop: "10px" }}>{data.description}</p>
                              </div>
                            </div>
                            </div>
                            {/* <div className="col-2 " > */}
                            <div className="col-md-2 col-5" >
                            <div className="mobile-flex-container">
                              <RxPencil1 class=" mr-4" style={{ color: "#646464", fontSize: '23px', cursor: "pointer" }} onClick={() => { editAcademics(data) }} aria-hidden="true" data-bs-toggle="modal" data-bs-target="#project" />
                              <RiDeleteBinLine className="ml-2" style={{ color: "#646464", fontSize: "21px", cursor: "pointer", marginTop: '-2px' }} aria-hidden="true" onClick={() => { deleteAcademics(data._id) }} />
                            </div>
                            </div>
                          </div>
                        </>
                      )
                    })
                    }
                  </>) : (<></>)}

                  {resumeData && resumeData.Academics && resumeData.Academics.length >= 4 ? (<></>) : (<>
                    {user && (user.resume || resumeId) ? (<>
                      <p class="btn p-0" onClick={() => { addAcademics() }} data-bs-toggle="modal" data-bs-target="#project" style={{ color: "#0196C5", fontSize: '15px' }}>+ Add academic / personal project</p>
                    </>) : (<>
                      <p class="btn p-0 " onClick={() => { toast.error("Add personal details first!!") }} style={{ color: "#0196C5", fontSize: '15px' }} >+ Add academic / personal project</p>
                    </>)}
                  </>)}



                </div>

              </div>
            </div>
            <hr className="dropdown-divider1 mb-4 mt-3"  style={{height: '0.05px', color: "rgb(185, 185, 185)"}} />


            <div class="container"
              style={{ padding: "25px" }}
            >
              <div class="row">
                <div class="col-3" style= {{minWidth: "200px"}}>
                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500" }}>SKILLS</p>
                </div>
                <div class="col-9">

                  {resumeData && resumeData.Skills ? (<>
                  <div className= 'row'>
                
                  {resumeData.Skills.map((data) => {
                    return (
                      <>
                      <div className="col-6" style= {{minWidth: "250px"}}>
                       <div className= "row">
                        <div className="col-7" style={{ marginBottom: "10px", fontSize: '17px' }}>
                        <p style={{fontSize: "15px", color: "#404040"}}><b>{data.name}</b></p>     
                              <div style={{ color: 'black',  marginTop: '-8px', marginRight: "30px" }}>
                              <div style={{ color: '#6E6E6E', fontSize: '15px', marginTop: '-8px' }}>
                              <p style={{ marginTop: "10px" }}>{data.level}</p>
                              </div>
                              </div>
                              </div> 
                          <div className="col-5" >
                          <div style = {{display: "flex"}}>
                        <RxPencil1 class=" mr-4" style={{ color: "#646464", fontSize: '20px', cursor: "pointer" }} onClick={() => { editSkill(data) }} aria-hidden="true" data-bs-toggle="modal" data-bs-target="#skill" />
                        <RiDeleteBinLine className="ml-2" style={{color: "#646464", fontSize: "18px", cursor: "pointer"}} aria-hidden="true" onClick={() => { deleteSkills(data._id) }} />
                      </div>
                      </div>
                      </div>
                      </div>
                            </>  )})} 
                            </div>  </>):
                            (<></>)}
                            

                  {resumeData && resumeData.Skills && resumeData.Skills.length >= 20 ? (<></>) : (<>
                    {user && (user.resume || resumeId) ? (<>
                      <p class="btn p-0 "onClick={() => { addSkill() }} data-bs-toggle="modal" data-bs-target="#skill" style={{ color: "#0196C5", fontSize: '15px' }} >+ Add skill</p>
                    </>) : (<>
                      <p class="btn p-0 " onClick={() => { toast.error("Add personal details first!!") }} style={{ color: "#0196C5", fontSize: '15px' }} >+ Add skill</p>
                    </>)}
                        </>)}
                      </div>
                      <div className="col-1" >
                      </div>
                    </div>
                  </div>
          </div>
        </div>
      </div>
</>)}



      {/* Model 1: Personal Details */}
      <div class="  modal fade" id="personalDetails" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  custom-modal-dialog">
          <div class="modal-content px-3 py-3" style={{borderRadius: '15px', marginTop: "20px"}}>
            <div class="modal-body">
              <div className="step-content container " >
                <div style={{textAlign: "end"}}>
                <button type="button" class="btn-close" ref={closePersonal} data-bs-dismiss="modal" style={{marginRight: "-10px"}} aria-label="Close"></button>
              </div>
                <p className=" title text-center mx-auto pb-3" style={{color: "#444343", marginTop: "10px", marginBottom: "15px", fontSize: "20px", fontWeight: "500" }}>Personal Details</p>
                <div className="sectionn ml-auto " >
                  <div className=" mx-auto " >
                    <div className='row'>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>First Name</label>
                          <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                            type="text"
                            class="ModelInput form-control "
                            placeholder="Enter first name"
                            value={user && personalDetails.firstName}

                            onChange={handlPersonalDetailsChange}
                            id="firstName"
                            name="firstName"
                          />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Last Name</label>
                          <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                            type="text"
                            class="ModelInput form-control "
                            placeholder="Enter last name"
                            value={user && personalDetails.lastName}
                            onChange={handlPersonalDetailsChange}
                            id="lastName"
                            name="lastName"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-4 mb-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Email</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="email"
                        class="ModelInput form-control "
                        placeholder="demo@gmail.com"
                        value={user && personalDetails.email}
                        onChange={handlPersonalDetailsChange}
                        id="email"
                        name="email"
                        readOnly
                      />
                    </div>
                    <div class="form-group my-4">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Contact number</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="number"
                        class="ModelInput form-control "
                        placeholder="Enter Phone Number"
                        value={user && personalDetails.phone}
                        onChange={handlPersonalDetailsChange}
                        id="phone"
                        name="phone"
                      />
                    </div>  
                    <div class="form-group mt-4 mb-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Current city</label>
                        <Select
                          // options={selectedCountry && citiesData[selectedCountry]?.map((city) => ({ value: city, label: city }))}
                          options={cityOptions}
                          placeholder={<div style={{ fontSize: '13px' }}>Select current city</div>}
                          onChange={(selectedOption) => {
                          setPersonalDetails({ ...personalDetails, address: selectedOption?.value })
                          }}
                          value={personalDetails.address ? { value: personalDetails.address, label: personalDetails.address } : null}
                          onInputChange={handleInputChange}
                          // noOptionsMessage={noOptionsMessage}
                          menuIsOpen={isTyping}
                          styles={customStyles}
                        />
                      </div>
                      <div class="form-group mt-4 mb-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Profile</label>
                        {/* <Select
                          options={profiles?.map((data) => ({ value: data, label: data }))}
                          placeholder={<div style={{ fontSize: '13px' }}>Select profile</div>}
                          onChange={(selectedOption) => {
                          setPersonalDetails({ ...personalDetails, profile: selectedOption?.value })
                          }}
                          value={personalDetails.profile ? { value: personalDetails.profile, label: personalDetails.profile } : null}
                        /> */}
                        <Select
                          options={filteredProfiles.map(profile => ({ value: profile, label: profile }))}
                          placeholder={<div style={{ fontSize: '13px' }}>Select profile</div>}
                          onChange={(selectedOption) => {
                            setPersonalDetails({ ...personalDetails, profile: selectedOption?.value })
                          }}
                          onInputChange={(inputValue) => {
                            setInputValue(inputValue);
                            setIsTyping1(inputValue.trim() !== '');
                            if (inputValue.trim() !== '') {
                              setFilteredProfiles(filterOptions(inputValue));
                            } else {
                              setFilteredProfiles([]);
                            }
                            // const filtered = inputValue.trim() !== '' ? filterOptions(inputValue) : [];
                            // setFilteredProfiles(filtered);
                            // setIsTyping1(inputValue.trim() !== '' && filtered.length > 0);
                          }}
                          inputValue={inputValue}
                          value={personalDetails.profile ? { value: personalDetails.profile, label: personalDetails.profile } : null}
                          styles={customStyles}
                          menuIsOpen={isTyping1}
                          // noOptionsMessage={noOptionsMessage3}
                        /> 
                      </div>
                      <div class="form-group mt-4 mb-2">
                        <label for="exampleInputEmail1 mt-2">Availability</label>
                        <select aria-label="Default select example"
                          class="form-select mt-1"
                          style={{ fontSize: "15px", paddingTop: "8px", paddingBottom: "6px",color: user && personalDetails.availability ?'black':'gray' }}
                          onChange={handlPersonalDetailsChange}
                          id="availability"
                          name="availability"
                          value={user && personalDetails.availability}
                          // placeholder={<div style={{ fontSize: '13px' }}>Select availability</div>}
                        >
                          <option disabled selected value="" style={{color:'gray'}}>Select Availability</option>
                          <option value="Immediate">Immediate</option>
                          <option value="Within 1 Week" style={{color:'black'}}>Within 1 Week</option>
                          <option value="Within 2 Weeks" style={{color:'black'}}>Within 2 Weeks</option>
                          <option value="Within 3 Weeks" style={{color:'black'}}>Within 3 Weeks</option>
                          <option value="Within 4 Weeks" style={{color:'black'}}>Within 4 Weeks</option>
                          <option value="Within 5 Weeks" style={{color:'black'}}>Within 5 Weeks</option>
                          <option value="Within 6 Weeks" style={{color:'black'}}>Within 6 Weeks</option>
                          <option value="Within 7 Weeks" style={{color:'black'}}>Within 7 Weeks</option>
                          <option value="Within 8 Weeks" style={{color:'black'}}>Within 8 Weeks</option>
                        </select>
                      </div>
                   {/*  <div class="form-group mt-4 mb-2">
                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Profile</label>
                        <input
                        type="text"
                        style={{ fontSize: '15px' }}
                        class="ModelInput form-control "
                        placeholder="eg. MERN Developer"
                        value={user && personalDetails.profile}
                        onChange={handlPersonalDetailsChange}
                        id="profile"
                        name="profile"
                        />
                        </div> */}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "-36px", textAlign: "end" }}>
              {personalDetails._id ? (<>
                <button onClick={modifyPersonalDetails} class="saveUpdate">{loading ? <Spinner /> : <span>Update</span>}</button>
              </>) : (<>
                <button onClick={updatePersonalDetails} class="saveUpdate">{loading ? <Spinner /> : <span>Save</span>}</button>
              </>)}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Model 2: Education */}
      <div class="  modal fade" id="education" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog  custom-modal-dialog">
          <div class="modal-content px-3 py-3" style={{borderRadius: '15px', marginTop: "20px"}}>
            <div class="modal-body">
              <div className="step-content container " >
              <div style={{textAlign: "end"}}>
              <button type="button" class="btn-close" ref={closeEducation} data-bs-dismiss="modal" style={{marginRight: "-10px"}} aria-label="Close"></button>
              </div>
              <p className=" title text-center mx-auto pb-3" style={{color: "#444343", marginTop: "10px", marginBottom: "15px", fontSize: "20px", fontWeight: "500" }}>Education Details</p>             
                <div className="sectionn ml-auto " >
                  <div className=" mx-auto " >
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>School/College</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                        class="ModelInput form-control "
                        placeholder="e.g. IGNOU"
                        value={education.school}
                        onChange={handleEducationChange}
                        id="school"
                        name="school"
                      />
                    </div>
                
                
                      <div class="form-group mt-3 mb-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "14px" }}>Degree</label>
                        <Select
                          options={Degree?.map((data) => ({ value: data, label: data }))}
                          placeholder={<div style={{ fontSize: '13px' }}>Select degree</div>}
                          onChange={(selectedOption) => {
                          setEducation({ ...education, degree: selectedOption?.value })
                          }}
                          value={education.degree ? { value: education.degree, label: education.degree } : null}
                        />
                      </div>


                      <div class="form-group mt-3 mb-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Specialization</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                        class="ModelInput form-control "
                        placeholder="e.g. Computer Science"
                        value={education.specialization}
                        onChange={handleEducationChange}
                        id="specialization"
                        name="specialization"
                      />
                    </div>
                    
                    
                    
                  
                   {/* <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Degree</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                        class="ModelInput form-control "
                        placeholder="e.g. MCA"
                        value={education.degree}
                        onChange={handleEducationChange}
                        id="degree"
                        name="degree"
                      />
                    </div>*/}
                    <div className='row'>
                      <div className='col-6'>
                        <div class=" form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Start date</label>
                          <input
                            type="date"
                            value={education.startDate && new Date(education.startDate).toISOString().split('T')[0]}
                            onChange={(e) => setEducation({ ...education, startDate: e.target.value })}
                            className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                          />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div class="form-group my-2">
                            <label for=" exampleInputEmail1" style={{ fontSize: "15px" }}>End date</label>
                            <input
                              type="date"
                              value={education.endDate && (new Date(education.endDate)).toISOString().split('T')[0]}
                              onChange={(e) => setEducation({ ...education, endDate: e.target.value })}
                                className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                            
                            />
                          </div>


                        {/*  <div key={education._id} className="form-check text-end float-right mr-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={education.pursuing}
                              id={education._id}
                              onChange={() => handleCheckboxChange(education._id)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={education._id}
                              style={{ marginTop: '-5px', marginLeft: '-10px', fontSize: '15px', fontWeight: '400' }}
                            >
                              Currently pursuing
                            </label>
                           </div> */}


                        </div>
                      </div>
                      <div className='row'>
                      <div className='col-6'>
                        <div class=" form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Performance Scale</label>
                          <Select
                          options= {[
                          {value: "Percentage", label: "Percentage"},
                          {value: "CGPA (Scale of 10)", label: "CGPA (Scale of 10)"},
                          {value: "CGPA (Scale of 9)", label: "CGPA (Scale of 9)"},
                          {value: "CGPA (Scale of 8)", label: "CGPA (Scale of 8)"},
                          {value: "CGPA (Scale of 7)", label: "CGPA (Scale of 7)"},
                          {value: "CGPA (Scale of 5)", label: "CGPA (Scale of 5)"},
                          {value: "CGPA (Scale of 4)", label: "CGPA (Scale of 4)"},
                        ]}
                          placeholder={<div style={{ fontSize: '13px' }}>Percentage</div>}
                          onChange={(selectedOption) => {
                          setEducation({ ...education, performanceScale: selectedOption?.value })
                          }}
                          value={education.performanceScale ? { value: education.performanceScale, label: education.performanceScale } : null}
                        />
                      </div>
                      </div>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for=" exampleInputEmail1" style={{ fontSize: "15px" }}>Performance</label>
                          <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                            type="number"
                            className=" ModelInput form-control"
                            placeholder="0.00"
                            value={education.performance}
                            onChange={handleEducationChange}
                            id="performance"
                            name="performance"
                           />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "-36px", textAlign: "end" }}>
              {education._id ? (<>
                <button  onClick={modifyEducation} class="saveUpdate">{loading ? <Spinner /> : <span>Update</span>} </button>
              </>) : (<>
                <button onClick={updateEducation} class="saveUpdate">{loading ? <Spinner /> : <span>Save</span>}</button>
              </>)}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Model 3: Job */}
      <div class="  modal fade" id="job" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog  custom-modal-dialog">
          <div class="modal-content px-3 py-3" style={{borderRadius: '15px', marginTop: "20px"}}>
            <div class="modal-body">
              <div className="step-content container " >
              <div style={{textAlign: "end"}}>
              <button type="button" class="btn-close" ref={closeJob} data-bs-dismiss="modal" style={{marginRight: "-10px"}} aria-label="Close"></button>
              </div>
              <p className=" title text-center mx-auto pb-3" style={{color: "#444343", marginTop: "10px", marginBottom: "15px", fontSize: "20px", fontWeight: "500" }}>Job details</p>             
                <div className="sectionn ml-auto " >
                  <div className=" mx-auto " >
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Profile</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                        class="ModelInput form-control "
                        placeholder="e.g. Software Developer"
                        value={job.profile}
                        onChange={handleJobChange}
                        id="profile"
                        name="profile"
                      />
                    </div>
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Organization</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                        class="ModelInput form-control "
                        placeholder="e.g. ABC Company"
                        value={job.organization}
                        onChange={handleJobChange}
                        id="organization"
                        name="organization"
                      />
                    </div>
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Location</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                        class="ModelInput form-control "
                        placeholder="e.g. Mumbai"
                        value={job.location}
                        onChange={handleJobChange}
                        id="location"
                        name="location"
                      />
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Start date</label>
                          <input
                            type="date"
                            value={job.startDate && new Date(job.startDate).toISOString().split('T')[0]}
                            onChange={(e) => setJob({ ...job, startDate: e.target.value })}
                            className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                          />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>End date</label>
                          <input
                            type="date"
                            value={job.endDate && new Date(job.endDate).toISOString().split('T')[0]}
                            onChange={(e) => setJob({ ...job, endDate: e.target.value })}
                            className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group ">
                      <label for="exampleInputEmail1" style={{ fontSize: '15px' }}>
                        Description
                      </label>
                      <textarea
                        class="ModelInput form-control "
                        style={{ fontSize: '15px', lineHeight: "20px" }}
                        rows="6" placeholder="Short description of work done..." cols="50" 
                        value={job.description}
                        onChange={handleJobChange}
                        id="description"
                        name="description" />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "-36px", textAlign: "end" }}>
              {job._id ? (<>
                <button  onClick={modifyJob}  class="saveUpdate" >{loading ? <Spinner /> : <span>Update</span>}</button>
              </>) : (<>
                <button onClick={updateJob}  class="saveUpdate" >{loading ? <Spinner /> : <span>Save</span>}</button>
              </>)}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Model 4: Internship */}
      <div class="  modal fade" id="internship" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog  custom-modal-dialog">
          <div class="modal-content px-3 py-3" style={{borderRadius: '15px', marginTop: "20px"}}>
            <div class="modal-body">
              <div className="step-content container " >
              <div style={{textAlign: "end"}}>
              <button type="button" class="btn-close" ref={closeInternship} data-bs-dismiss="modal" style={{marginRight: "-10px"}} aria-label="Close"></button>
              </div>
              <p className=" title text-center mx-auto pb-3" style={{color: "#444343", marginTop: "10px", marginBottom: "15px", fontSize: "20px", fontWeight: "500" }}>Internship details</p>             
                <div className="sectionn ml-auto " >
                  <div className=" mx-auto " >
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Profile</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                         class="ModelInput form-control "
                        placeholder="e.g. Software Developer"
                        value={internship.profile}
                        onChange={handleInternshipChange}
                        id="profile"
                        name="profile"
                      />
                    </div>
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Organization</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                         class="ModelInput form-control "
                        placeholder="e.g. ABC Company"
                        value={internship.organization}
                        onChange={handleInternshipChange}
                        id="organization"
                        name="organization"
                      />
                    </div>
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Location</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                         class="ModelInput form-control "
                        placeholder="e.g. Mumbai"
                        value={internship.location}
                        onChange={handleInternshipChange}
                        id="location"
                        name="location"
                      />
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Start date</label>
                          <input
                            type="date"
                            value={internship.startDate && new Date(internship.startDate).toISOString().split('T')[0]}
                            onChange={(e) => setInternship({ ...internship, startDate: e.target.value })}
                            className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                          />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>End date</label>
                          <input
                            type="date"
                            value={internship.endDate && new Date(internship.endDate).toISOString().split('T')[0]}
                            onChange={(e) => setInternship({ ...internship, endDate: e.target.value })}
                            className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group ">
                      <label for="exampleInputEmail1" style={{ fontSize: '15px' }}>
                        Description
                      </label>
                      <textarea rows="6" placeholder="Short description of work done..." cols="50"  class="ModelInput form-control "  style={{ fontSize: '15px', lineHeight: "20px" }}
                        value={internship.description}
                        onChange={handleInternshipChange}
                        id="description"
                        name="description" />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "-36px", textAlign: "end" }}>
              {internship._id ? (<>
                <button type="button " onClick={modifyInternship}  class="saveUpdate" >{loading ? <Spinner /> : <span>Update</span>}</button>
              </>) : (<>
                <button type="button " onClick={updateInternship}  class="saveUpdate" >{loading ? <Spinner /> : <span>Save</span>}</button>
              </>)}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Model 5: Position of Responsibility 
      <div class="  modal fade" id="por" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"> </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className="step-content container " >
                <h3 className=" title text-center mx-auto pb-3" style={{ marginTop: "30px", marginBottom: "20px" }}>Position of Responsibility</h3>
                <div className="sectionn ml-auto " >
                  <div className=" mx-auto " >
                    <div class="form-group ">
                      <label for="exampleInputEmail1" style={{ fontSize: '15px' }}>
                        Description
                      </label>
                      <p style={{ color: '#808486', fontSize: "15px" }}>If you have been/are an active part of societies, conducted any events or led a team, add details here</p>
                      <textarea rows="6" placeholder="e.g. Led a team of 5 volunteers to plan and conduct activities for literary event in college fest" cols="50"  class="ModelInput form-control " style={{ fontSize: "15px" }} />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "-36px" }}></div>
            </div>
            <div class="modal-footer">
              <button type="button " class="btn btn-info" style={{ fontSize: "15px", color: "white", borderRadius: '5px', paddingLeft: "35px", paddingRight: "35px", backgroundColor: "#4AA9E0" }}>Update</button>
            </div>
          </div>
        </div>
      </div>
*/}

      {/* Model 6: Training/Courses */}
      <div class="  modal fade" id="training" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog  custom-modal-dialog">
          <div class="modal-content px-3 py-3" style={{borderRadius: '15px', marginTop: "20px"}}>
            <div class="modal-body">
              <div className="step-content container " >
              <div style={{textAlign: "end"}}>
              <button type="button" class="btn-close" ref={closeTraining} data-bs-dismiss="modal" style={{marginRight: "-10px"}} aria-label="Close"></button>
              </div>
              <p className=" title text-center mx-auto pb-3" style={{color: "#444343", marginTop: "10px", marginBottom: "15px", fontSize: "20px", fontWeight: "500" }}>Training details</p>             
                <div className="sectionn ml-auto " >
                  <div className=" mx-auto " >
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Training program</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                         class="ModelInput form-control "
                        placeholder="e.g. Analytics"
                        value={training.program}
                        onChange={handleTrainingChange}
                        id="program"
                        name="program"
                      />
                    </div>
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Organization</label>
                      <input
                        style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                        class="ModelInput form-control "
                        placeholder="e.g. ABC Company"
                        value={training.organization}
                        onChange={handleTrainingChange}
                        id="organization"
                        name="organization"
                      />
                    </div>
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Location</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                         class="ModelInput form-control "
                        placeholder="e.g. Mumbai"
                        value={training.location}
                        onChange={handleTrainingChange}
                        id="location"
                        name="location"
                      />
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Start date</label>
                          <input
                            type="date"
                            value={training.startDate && new Date(training.startDate).toISOString().split('T')[0]}
                            onChange={(e) => setTraining({ ...training, startDate: e.target.value })}
                            className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                          />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>End date</label>
                          <input
                            type="date"
                            value={training.endDate && new Date(training.endDate).toISOString().split('T')[0]}
                            onChange={(e) => setTraining({ ...training, endDate: e.target.value })}
                            className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group ">
                      <label for="exampleInputEmail1" style={{ fontSize: '15px' }}>
                        Description
                      </label>
                      <textarea rows="6" placeholder="Short description about training..." cols="50"  class="ModelInput form-control "  style={{ fontSize: '15px', lineHeight: "20px" }}
                        value={training.description}
                        onChange={handleTrainingChange}
                        id="description"
                        name="description" />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "-36px", textAlign: "end" }}>
              {training._id ? (<>
                <button type="button " onClick={modifyTraining}  class="saveUpdate" >{loading ? <Spinner /> : <span>Update</span>}</button>
              </>) : (<>
                <button type="button " onClick={updateTraining}  class="saveUpdate" >{loading ? <Spinner /> : <span>Save</span>}</button>
              </>)}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Model 7: Academic/personal project */}
      <div class="  modal fade" id="project" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog  custom-modal-dialog">
          <div class="modal-content px-3 py-3" style={{borderRadius: '15px', marginTop: "20px"}}>
            <div class="modal-body">
              <div className="step-content container " >
              <div style={{textAlign: "end"}}>
              <button type="button" class="btn-close" ref={closeAcademics} data-bs-dismiss="modal" style={{marginRight: "-10px"}} aria-label="Close"></button>
              </div>
              <p className=" title text-center mx-auto pb-3" style={{color: "#444343", marginTop: "10px", marginBottom: "15px", fontSize: "20px", fontWeight: "500" }}>Project details</p>             
                <div className="sectionn ml-auto " >
                  <div className=" mx-auto " >
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Title</label>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                         class="ModelInput form-control "
                        placeholder="e.g. Face recognization system"
                        value={academic.title}
                        onChange={handleAcademicChange}
                        id="title"
                        name="title"
                      />
                    </div>
                     <div className='row'>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Start date</label>
                          <input
                            type="date"
                            value={academic.startDate && new Date(academic.startDate).toISOString().split('T')[0]}
                            onChange={(e) => setAcademic({ ...academic, startDate: e.target.value })}
                            className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                          />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div class="form-group my-2">
                          <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>End date</label>
                          <input
                            type="date"
                            value={academic.endDate && new Date(academic.endDate).toISOString().split('T')[0]}
                            onChange={(e) => setAcademic({ ...academic, endDate: e.target.value })}
                            className="ModelInput form-control"
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group ">
                      <label for="exampleInputEmail1" style={{ fontSize: '15px' }}>
                        Description
                      </label>
                      <textarea rows="6" placeholder="Short description about project..." cols="50"  class="ModelInput form-control "  style={{ fontSize: '15px', lineHeight: "20px" }}
                        value={academic.description}
                        onChange={handleAcademicChange}
                        id="description"
                        name="description" />
                    </div>
                    <div class="form-group my-2">
                      <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Project link</label>
                      <p style={{ color: '#808486', fontSize: "14px" }}>If you have multiple project links or an offline project, upload and provide link to google drive</p>
                      <input
                            style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                        type="text"
                         class="ModelInput form-control "
                        placeholder="e.g. http://myproject.com"
                        value={academic.link}
                        onChange={handleAcademicChange}
                        id="link"
                        name="link"
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div style={{ marginTop: "-36px", textAlign: "end" }}>
              {academic._id ? (<>
                <button type="button " onClick={modifyAcademic}  class="saveUpdate">{loading ? <Spinner /> : <span>Update</span>}</button>
              </>) : (<>
                <button type="button " onClick={updateAcademic}  class="saveUpdate" >{loading ? <Spinner /> : <span>Save</span>}</button>
              </>)}
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* Model 8: Skills */}
      <div class="  modal fade" id="skill" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog  custom-modal-dialog">
          <div class="modal-content px-3 py-3" style={{borderRadius: '15px', marginTop: "20px"}}>
            <div class="modal-body">
              <div className="step-content container " >
              <div style={{textAlign: "end"}}>
              <button type="button" class="btn-close" ref={closeSkills} data-bs-dismiss="modal" style={{marginRight: "-10px"}} aria-label="Close"></button>
              </div>
              <p className=" title text-center mx-auto pb-3" style={{color: "#444343", marginTop: "10px", marginBottom: "15px", fontSize: "20px", fontWeight: "500" }}>Skills</p>             
                <div className="sectionn ml-auto " >
                  <div className=" mx-auto " >
                  <div className="form-group my-2">
  <label htmlFor="exampleInputEmail1" style={{ fontSize: "15px" }}>
    Add Skills
  </label>
  <Select
  name="name"
  
      value={ skillVissible? 
       { label: "",
        value: "",}
        :
         skill.name === 'Other' ? null : {
      label: skill.name,
      value: skill.name}
    }
    onChange={(selectedSkill) => {
      if (selectedSkill && selectedSkill.value === 'Other') {
        setskill({...skill, name: "" });
        setSkillVissible(true);
        
      } else {
        setSkillVissible(false);
        setskill({...skill,  name: selectedSkill.value });
      }
    }}
    options={pdskills?.map((data) => ({ value: data, label: data }))}
    placeholder="e.g. Adobe photoshop"
    style={{ fontSize: '15px' }}
    onInputChange={handleInputChange2}
    styles={customStyles}
    // noOptionsMessage={noOptionsMessage2}
    menuIsOpen={isTyping2}
  />
</div>
{skillVissible && (
  <div className="form-group mt-4 mb-2">
    <label htmlFor="exampleInputEmail1" style={{ fontSize: "15px" }}>Please Specify</label>
    <input
      style={{ fontSize: '15px' }}
      type="text"
      className="ModelInput form-control py-3"
      placeholder="e.g. Adobe photoshop"
      value={skill.name}
      onChange={handleSkillChange}
      id="name"
      name="name"
    />
  </div>
)}
 <div className="form-group my-2">
  <label htmlFor="exampleInputEmail1" style={{ fontSize: "15px" }}>
   Level
  </label>
  <Select
  name="level"
  value={{ label: skill.level, value: skill.level }}
    onChange={(selectedLevel) => {
           setskill({...skill,  level: selectedLevel.value });
    }}
    options={[
    { label: "Beginner", value: "Beginner" },          // Option for Beginner level
    { label: "Intermediate", value: "Intermediate" },   // Option for intermediate level
    { label: "Advanced", value: "Advanced" }            // Option for advanced level
]}
    style={{ fontSize: '15px' }}
  />
</div>
                    
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "-36px", textAlign: "end" }}>
              {skill._id ? (<>
                <button type="button " onClick={modifySkill}  class="saveUpdate">{loading ? <Spinner /> : <span>Update</span>}</button>
              </>) : (<>
                <button type="button " onClick={updateSkill}  class="saveUpdate" >{loading ? <Spinner /> : <span>Save</span>}</button>
              </>)}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>
  )
}

export default EditResume;
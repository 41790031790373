import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Student_New/UserApplications.scss';
import '../Student_New/Jobs.scss';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosArrowForward, IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { MONTH_DETAILS } from '../data';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import ScaleLoader from "react-spinners/ScaleLoader";
import { Grid } from "@mui/material";
import './AdminDashboard.css';
import { LuClipboardEdit } from "react-icons/lu";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loginUserForAdminCandidate } from "../../actions/userActions";
import { RiDeleteBinLine } from "react-icons/ri";
import Popup from "reactjs-popup";




function AdminCandidates() {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [candidateData, setCandidateData] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [sortByJobsApplied, setSortByJobsApplied] = useState(null);
    const [sortByJobsRejected,setSortByJobsRejected] = useState(null);
    const [sortByJobsShortlisted,setSortByJobsShortlisted] = useState(null);
    const [sortByJobsHired,setSortByJobsHired] =useState(null);
    const [sortField, setSortField] = useState(null); // State for sorting field
    const [sortOrder, setSortOrder] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loginType, setLoginType] = useState('User');
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const borderRemoveRef = useRef(null);
    const handleLogin= async (userId)=>{
      if (loginType === 'User') {
        // Assuming loginUser is an action creator
        await dispatch(loginUserForAdminCandidate(userId, loginType));
        localStorage.setItem('userType', loginType);
        navigate('/');
      }
    }

    useEffect(() => {
      if (localStorage.getItem('token')) {
        if (user?.role === 'User') {
          navigate('/');
        } else if (user?.role === 'Employer') {
          // navigate('/employerApplication');
          navigate('/employerApplication/Internship');
        }
      }
    }, [isAuthenticated, user, navigate]);
    const GetCandidateData = async (searchInput = "") => {
      setLoading(true);
      // console.log(currentPage,"currentPage")
      try {
         const { data } = await axios.post("/api/user/getUserDataForAdmin", {
          // searchInput: search,
          searchInput,
          page: currentPage,
          pageSize: 15, // Display 15 data per page
          sortBy: sortField,
          sortOrder: sortOrder,
        });
        setLoading(false);
        setCandidateData(data.data);
        setTotalPages(data.totalPages);
        setTotalItem(data.totalItems);
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data, {
          style: {
            padding: "18px",
          },
        });
      }
    };

    useEffect(() => {
      GetCandidateData()
    }, [sortField, sortOrder]);
    const handleSortnew = (field,direction) => {
      setSortField(field);
      setSortOrder(direction);
    
    };
    const handleSort = (type) => {

      if (type === 'jobsApplied') {
          const sortedData = [...candidateData].sort((a, b) => {
              return a.appliedJobs.length - b.appliedJobs.length;
          });
          const sortedCandidateData = sortByJobsApplied === 'asc' ? sortedData : sortedData.reverse();
          setSortByJobsApplied(sortByJobsApplied === 'asc' ? 'desc' : 'asc');
          setCandidateData(sortedCandidateData);
      } else if (type === 'jobsRejected') {
          const sortedData = [...candidateData].sort((a, b) => {
              return countRejectedJobs(a.appliedJobs) - countRejectedJobs(b.appliedJobs);
          });
          const sortedCandidateData = sortByJobsRejected === 'asc' ? sortedData : sortedData.reverse();
          setSortByJobsRejected(sortByJobsRejected === 'asc' ? 'desc' : 'asc');
          setCandidateData(sortedCandidateData);
      } else if (type === 'jobsShortlisted') {
          const sortedData = [...candidateData].sort((a, b) => {
              return countShortlistedJobs(a.appliedJobs) - countShortlistedJobs(b.appliedJobs);
          });
          const sortedCandidateData = sortByJobsShortlisted === 'asc' ? sortedData : sortedData.reverse();
          setSortByJobsShortlisted(sortByJobsShortlisted === 'asc' ? 'desc' : 'asc');
          setCandidateData(sortedCandidateData);
      } else if (type === 'jobsHired') {
          const sortedData = [...candidateData].sort((a, b) => {
              return countHiredJobs(a.appliedJobs) - countHiredJobs(b.appliedJobs);
          });
          const sortedCandidateData = sortByJobsHired === 'asc' ? sortedData : sortedData.reverse();
          setSortByJobsHired(sortByJobsHired === 'asc' ? 'desc' : 'asc');
          setCandidateData(sortedCandidateData);
      }
    };
    const handlePageChange = (newPage) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };

    useEffect(() => {
      GetCandidateData();
      window.scrollTo(0, 0);
    }, [currentPage]);
   

    //FUNCTIONS FOR GETTING APPLIED JOB STATUS WISE DATA:

    const countHiredJobs=(appliedJobs)=> {
      let hiredCount = 0;
      appliedJobs.forEach(job => {
          if (job.status === "Hired") {
              hiredCount++;
          }
      });
      return hiredCount;
  }

  const countRejectedJobs=(appliedJobs)=> {
    let rejectedCount = 0;
    appliedJobs.forEach(job => {
        if (job.status === "NotIntrested") {
          rejectedCount++;
        }
    });
    return rejectedCount;
}

const countShortlistedJobs=(appliedJobs)=> {
  let shortlistedCount = 0;
  appliedJobs.forEach(job => {
      if (job.status === "Shortlisted") {
        shortlistedCount++;
      }
  });
  return shortlistedCount;
}


// ACTIVE DEACTIVE OPTION: 


 
  const [id, setId] = useState("");
  const [userStatus, setUserStatus] = useState("");


  const idSelector=(_id)=>{
      setId(_id);
  }

  const ActiveUser=async()=>{
    window.scrollTo(0, 0);
    setLoading(true);
    try{
  const { data } = await axios.post("/api/user/activedeactive", {
      id: id,
      status: "active",
    });
    // GetCandidateData();
    // setCandidateData(candidateData?.map(user => user._id === id ? { ...user, active: "Active" } : user));
    const userIndex = candidateData.findIndex(user => user._id === id);
    // If the user is found, update the active field directly
    if (userIndex !== -1) {
      candidateData[userIndex].active = true;
    }
    toast.success("User is active now");
    setLoading(false);
  } catch (error) {
    setLoading(false);
    console.log(error)
    toast.error(error.response.data, {
      style: {
        padding: "18px",
      },
    });
  }
  }

  const DeActiveUser=async()=>{
    window.scrollTo(0, 0);
    setLoading(true);
    try{
    const { data } = await axios.post("/api/user/activedeactive", {
      id: id,
      status: "de-active",
    });
    // GetCandidateData();
    // setCandidateData(candidateData?.map(user => user._id === id ? { ...user, active: "De-active" } : user));
    const userIndex = candidateData.findIndex(user => user._id === id);
    // If the user is found, update the active field directly
    if (userIndex !== -1) {
      candidateData[userIndex].active = false;
    }
    toast.success("User is not active now")
    setLoading(false);
  } catch (error) {
    setLoading(false);
    console.log(error)
    toast.error(error.response.data, {
      style: {
        padding: "18px",
      },
    });
  }
  }


  // SEARCH OPTION: 

  const [search, setSearch] = useState("");
  const handleSearchInput = (e) =>{
    setSearch(e.target.value);
  }

  // const handleSearch = () =>{
  //   GetCandidateData();
  //   setSearch("");
  // }
  const handleSearch = () => {
    if (search.trim() !== "") { // Only call GetJobData with search input if it's not empty
      GetCandidateData(search.trim());
      setSearch("");
    } else {
      setCurrentPage(1)
      if (currentPage !== 1) { // Check if current page is not already 1
        setCurrentPage(1); // Set current page to 1
      }
      GetCandidateData(); // If search input is empty, fetch all data
      // setCurrentPage(1, () => {
      //   GetCandidateData("");
      // });
      setSearch("");
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        handleSearch();
    }
  }



   // FOR AUTHENTICATION
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    useEffect(() => {
            if(!localStorage.getItem('token')  || !localStorage.getItem('userType') || localStorage.getItem('userType') !== "Admin"){
              navigate("/admin_login");
            }
          });
    //  END

    const initialForm = {
      _id: "",
      name:"",
      phone:"",
      city:"",
      availability:"",
    };
    
    const [candidate, setCandidate] = useState(initialForm);
    useEffect(() => {
      borderRemoveRef.current.scrollTop = 0;
    }, [candidate._id]);
    const fetchData = async (_id) => {
      borderRemoveRef.current.scrollTop = 0;
      const response = await fetch(`/api/admin/getUserToUpdatedata/${_id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
  
      // console.log(data,"user data");
      setCandidate({
        _id: data.user._id,
        name: data.user.name ||"",
        phone: data.user.phone || "",
        city: data.user.city || "",
        availability: data.user.availability || "",
        email:data.user.email,
      })
    };
    const handleUserChange = (e) => {
      setCandidate({
        ...candidate,
        [e.target.name]: e.target.value,
      });
    };
    const handleUpdate = async () => {
      setLoading(true);
      if (!candidate.name) {
        setLoading(false);
        toast.error("Name is required!!");
      } else if (!candidate.phone) {
        setLoading(false);
        toast.error("Phone is required!!");
      } else if (!candidate.city) {
        setLoading(false);
        toast.error("City is required!!");
      }
      else if (candidate.availability == "Select availability") {
        setLoading(false);
        toast.error("Select availability!!");
      }
      else {
        // console.log(candidate.email,"email")
        try {
          const { data } = await axios.post("/api/admin/udateuserdatafromadminsite", {
            name: candidate.name,
            phone: candidate.phone,
            city: candidate.city,
            availability: candidate.availability,
            email:candidate.email,
          });
          setLoading(false);
          toast.success("User Updated Successfully!", {
            style: {
              // padding: "18px",
            },
          });
          document.querySelector('.btn-close').click();
          GetCandidateData();      
        setCandidate({
          name: "",
          phone: "",
          city: "",
          availability: "Select availability"
        });
        } catch (error) {
          setLoading(false);
          toast.error(error.response.data, {
            style: {
              padding: "18px",
            },
          });
        }
      }
    };

    const [showModal, setShowModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
  
    const handleDeleteConfirmation = (id) => {
      setIdToDelete(id);
      setShowModal(true);
    };
  
    const handleConfirmDelete = async () => {
      await handleDeleteCandidate(idToDelete);
      setShowModal(false);
    };
  
    const handleCancelDelete = () => {
      setShowModal(false);
    };
    const handleDeleteCandidate =async (id)=>{
      setLoading(true);
      try {
        await axios.post(`/api/admin/deleteUserFromAdminside/${id}`);
  
        const canIndex = candidateData.findIndex(job => job._id === id);
    
        if (canIndex !== -1) {
          const updatedCanData = [...candidateData];
          updatedCanData.splice(canIndex, 1);
          setCandidateData(updatedCanData);
        }
        toast.success('User Deleted Successfully');
        setLoading(false);
        await axios.post('/api/admin/logDeleteAttempt', { userId: id });
        // const localStorageId = localStorage.getItem('id');
        // if (localStorageId && localStorageId === id) {
        //   localStorage.removeItem('userType');
        //   localStorage.removeItem('token');
        //   localStorage.removeItem('id');
        // }
      } catch (error) {
        console.error('Error deleting job:', error);
      }
    }

return (
<>
<div className="my-applications">
      {loading ? (
        <>
          <div className="my-auto" style={{ textAlign: 'center' }}>
            <p className= "loaderLoader"
              style={{
                color: '#4B92C8',
                fontWeight: '500',
                fontSize: '20px',
                marginLeft: '16px',
                marginBottom: '5px',
              }}
            >
              Loading..
            </p>
            <PropagateLoader
              color={'#4B92C8'}
              loading={true}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
              style={{ marginLeft: '-4px' }}
            />
          </div>
        </>
      ) : (
        <>
    
              <p className='borderremove' style={{color: "#494949", fontSize: "20px", fontWeight: "500", backgroundColor: "white",  borderRadius: "10px", paddingLeft: '10%', paddingRight: '10%', paddingTop: '1%', paddingBottom: '1%' }}>Candidate Details</p>
              <div className="AdminSearchBox">
                    <input className="AdminSearchInput" type="text" placeholder="Search by name, email, phone" 
                    onChange={handleSearchInput}
                    value= {search}
                    onKeyPress={handleKeyPress}
                    />
                    <button className="AdminSearchButton" onClick={handleSearch}>Search</button>
                </div>
          <div className="table-box table-responsive border borderremove px-0" >
              <table className="applications table" style={{marginLeft: "-2px"}}>
              <thead>
              <tr>
              <th className="tableheadingstyle1" style={{minWidth:'150px'}}>NAME</th>
              <th className="tableheadingstyle">EMAIL</th>
              <th className="tableheadingstyle10" style={{minWidth:'141px'}}>PHONE</th>
              <th width='50px' className="tableheadingstyle9">USER SINCE</th>
              <th style={{textAlign: "center"}} className="tableheadingstyle3">PROFILE STATUS</th>  
              <th style={{textAlign: "center"}} className="tableheadingstyle">EDIT PROFILE</th> 
              <th style={{textAlign: "center"}} className="tableheadingstyle">DELETE CANDIDATE</th>
              {/* <th style={{textAlign: "center"}}>JOBS APPLIED</th>
              <th style={{textAlign: "center"}}>JOBS REJECTED</th>
              <th style={{textAlign: "center"}}>JOBS SHORTLISTED</th>
              <th style={{textAlign: "center"}}>JOBS HIRED</th> */}
              {/* <th style={{ textAlign: "center", cursor: "pointer" }} onClick={() => handleSort('jobsApplied')} className="tableheadingstyle18">
                <div style={{display:'flex' ,gap:'0px'}}>

                JOBS APPLIED {sortByJobsApplied === 'asc' ? <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: '2em' }}/> : <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: '2em' }}/>}
                </div>
              </th> */}
              <th style={{ textAlign: "center", cursor: "pointer" }} className="tableheadingstyle18">
                  <div style={{ display: 'flex', gap: '3px' }}>

                    JOBS APPLIED
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: '1.3em', color: sortField === "jobsApplied" && sortOrder === "asc" ? 'white' : '#ddd' }} onClick={() => handleSortnew("jobsApplied", "asc")} />
                      <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: '1.3em', color: sortField === "jobsApplied" && sortOrder === "desc" ? 'white' : '#ddd' }} onClick={() => handleSortnew("jobsApplied", "desc")} />
                    </div>
                  </div>
              </th>
              {/* <th style={{ textAlign: "center", cursor: "pointer" }} onClick={() => handleSort('jobsRejected')} className="tableheadingstyle18">
                <div style={{display:'flex',gap:'0px'}}>

                JOBS REJECTED {sortByJobsRejected === 'asc' ? <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: '2em' }}/> : <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: '2em' }}/>}
                </div>
              </th> */}
              <th style={{ textAlign: "center", cursor: "pointer" }} className="tableheadingstyle18">
                  <div style={{ display: 'flex', gap: '3px' }}>

                  JOBS REJECTED
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: '1.3em',color: sortField === "jobsRejected" && sortOrder === "asc" ? 'white' : '#ddd' }} onClick={() => handleSortnew("jobsRejected", "asc")} />
                      <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: '1.3em',color: sortField === "jobsRejected" && sortOrder === "desc" ? 'white' : '#ddd' }} onClick={() => handleSortnew("jobsRejected", "desc")} />
                    </div>
                  </div>
              </th>
              {/* <th style={{ textAlign: "center", cursor: "pointer" }} onClick={() => handleSort('jobsShortlisted')} className="tableheadingstyle18">
              <div style={{display:'flex',gap:'0px'}}>

                JOBS SHORTLISTED {sortByJobsShortlisted === 'asc' ? <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: '2em' }}/> : <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: '2em' }}/>}
              </div>
              </th> */}
              <th style={{ textAlign: "center", cursor: "pointer" }} className="tableheadingstyle18">
                  <div style={{ display: 'flex', gap: '3px' }}>

                  JOBS SHORTLISTED
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: '1.3em',color: sortField === "jobsShortlisted" && sortOrder === "asc" ? 'white' : '#ddd' }} onClick={() => handleSortnew("jobsShortlisted", "asc")} />
                      <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: '1.3em',color: sortField === "jobsShortlisted" && sortOrder === "desc" ? 'white' : '#ddd' }} onClick={() => handleSortnew("jobsShortlisted", "desc")} />
                    </div>
                  </div>
              </th>
              {/* <th style={{ textAlign: "center", cursor: "pointer" }} onClick={() => handleSort('jobsHired')} className="tableheadingstyle17">
              <div style={{display:'flex',gap:'0px'}}>

                JOBS HIRED {sortByJobsHired === 'asc' ? <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: '2em' }}/> : <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: '2em' }}/>}
              </div>
              </th> */}
              <th style={{ textAlign: "center", cursor: "pointer" }} className="tableheadingstyle17">
                  <div style={{ display: 'flex', gap: '3px' }}>

                  JOBS HIRED
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: '1.3em',color: sortField === "jobsHired" && sortOrder === "asc" ? 'white' : '#ddd' }} onClick={() => handleSortnew("jobsHired", "asc")} />
                      <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: '1.3em' ,color: sortField === "jobsHired" && sortOrder === "desc" ? 'white' : '#ddd'}} onClick={() => handleSortnew("jobsHired", "desc")} />
                    </div>
                  </div>
              </th>
              </tr>
              </thead>
              <tbody>
              {loading2? 
            <>
                <Grid item xs={12} md={12} container justifyContent="center" alignItems="center" style={{ paddingLeft: "5%" }} >
                <div className=" mx-auto" style={{ marginBottom: "20px", marginTop: "20px", justifyContent: "center", justifyItem: "center", textAlign: "center" }}>
                  <ScaleLoader
                    color={'#4B92C8'}
                    loading={true}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              </Grid>
            </> :
            <>
              {candidateData.length != 0 ? (
            <>
                {candidateData.map((data, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: "14px", cursor: 'pointer',minWidth:'150px',textDecoration:"underline",color:"#4B92C8"}} onClick={() => handleLogin(data._id)} className="tableheadingstyle1">{data.name}</td>
                  {/* <td style={{fontSize: "14px"}}> {data.email.split('@')[0]+" "}<span>@</span>{data.email.split('@')[1]}</td> */}
                  <td style={{fontSize: "14px"}} className="tableheadingstyle">{data.email.split('@')[0]}<span>@</span>{data.email.split('@')[1]}</td>
                  <td style={{fontSize: "14px"}} className="tableheadingstyle10">{data.phone? data.phone: "Not Available"}</td>
                  <td style={{fontSize: "14px", minWidth: "140px"}} className="tableheadingstyle9">{(data.dataAdded).slice(8, 10) + " " + MONTH_DETAILS[parseInt(data.dataAdded.slice(5, 7) , 10)] + "' " + (data.dataAdded).slice(2, 4)}</td>
                  <td style={{textAlign: "center"}} className="tableheadingstyle3">
                  <div class="dropdown3 ml-4" style={{borderRadius: "5px", border: "solid 1px #4B92C8", marginBottom: "-10px"}} >
                       <button class="btn dropdown-toggle" type="button"  data-toggle="dropdown" aria-expanded="false"onClick={()=>idSelector(data._id)} style={{fontSize: '15px', width:'100px'}}>
                       {data.active == false? 'De-active' : 'Active'}
                        </button>
                        <ul class="dropdown-menu dropdown-menu-light px-2 custom-dropdown-menu" aria-labelledby="dropdownMenuButton2"> 
                        {data.active == false? 
                        <li><a class="dropdown-item border text-center py-2" style={{backgroundColor: "#4B92C8", color: "white", borderRadius: "5px", cursor: "pointer", fontSize: '15px'}}  onClick={ActiveUser}>Active</a></li>
                        :
                         <li><a class="dropdown-item border text-center py-2" style={{backgroundColor: "#4B92C8", color: "white", borderRadius: "5px", cursor: "pointer", fontSize: '15px'}}  onClick={DeActiveUser}>De-active</a></li>
                         }
                         </ul>
                         </div>
                  </td>
                  <td style={{fontSize: "14px", textAlign: "center"}}><LuClipboardEdit className="docIcon" style= {{fontSize: "21px", color: "#4B92C8"}} onClick={() => fetchData(data._id)} data-bs-toggle="modal" data-bs-target="#exampleModal" /></td>
                  <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle deletehovering">
                      <RiDeleteBinLine
                        className="docIcon"
                        style={{ fontSize: "23px", color: '#646464' }}
                        onClick={() => handleDeleteConfirmation(data._id)}
                      />
                    </td>
                  <td style={{fontSize: "14px", textAlign: "center"}} className="tableheadingstyle18">{data.appliedJobs.length}</td>
                  <td style={{fontSize: "14px", textAlign: "center"}} className="tableheadingstyle18"> {countRejectedJobs(data.appliedJobs)}</td>
                  <td style={{fontSize: "14px", textAlign: "center"}} className="tableheadingstyle18">{countShortlistedJobs(data.appliedJobs)}</td>
                  <td style={{fontSize: "14px", textAlign: "center"}} className="tableheadingstyle17">{countHiredJobs(data.appliedJobs)}</td>    
                  </tr>
                ))}
                </>):(<>
                  <p style={{padding: "15px  20px", minWidth: "200px", paddingBottom: "10", color: "red"}}>No data found!</p>
                </>)}
                </>}
                </tbody>
                </table>
              </div>

              {/* pagination */}
              {totalPages > 1 ? (
                <>
                   <div className="pagination" style={{ marginTop: "-20px" }}>
                        <p onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                          <IoIosArrowBack className={currentPage === 1 ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                        <span style={{ fontSize: "16px" }}>
                          {currentPage} / {totalPages}
                        </span>
                        <p onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                          <IoIosArrowForward className= {currentPage === totalPages ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                      </div>
                </>
              ) : (
                <></>
              )}
     
        </>
      )}
    </div>  

    {/* EDIT PROFILE MODEL */}
    
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content borderremove123" ref={borderRemoveRef} style={{ overflowY: 'auto', borderRadius: "2px", height: 'auto' }}>
          <div class="modal-body">
            <div style={{ textAlign: 'end', paddingRight: "30px" }}>
              <button style={{ position: "fixed" }} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {/*EDIT APPLICATION CODE START HERE*/}
            <div className="container" >
              <p className="text-center mx-auto" style={{ margin: "40px 0px", fontSize: "20px", fontWeight: "500", color: "#5F5F5F" }}>
                Edit Profile
              </p>
              <div className="section mx-auto" style={{ width: "90%" }}>
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      class="form-control mt-1"
                      style={{ fontSize: "15px", paddingTop: "18px", paddingBottom: "18px" }}
                      placeholder="John Deo"
                      value={candidate && candidate.name}
                      onChange={handleUserChange}
                      id="name"
                      name="name"
                    />
                  </div>

                  <div class="form-group ">
                    <label for="exampleInputEmail1 mt-2">Phone</label>
                    <input
                      type="text"
                      class="form-control mt-1"
                      style={{ fontSize: "15px", paddingTop: "18px", paddingBottom: "18px" }}
                      placeholder="Enter Phone No."
                      onChange={handleUserChange}
                      id="phone"
                      name="phone"
                      value={candidate && candidate.phone}
                    />
                  </div>
                  <div class="form-group ">
                    <label for="exampleInputEmail1 mt-2">City</label>
                    <input
                      type="text"
                      class="form-control mt-1"
                      style={{ fontSize: "15px", paddingTop: "18px", paddingBottom: "18px" }}
                      placeholder="Enter City Name"
                      onChange={handleUserChange}
                      id="city"
                      name="city"
                      value={candidate && candidate.city}
                    />
                  </div>


                  <label for="exampleInputEmail1 mt-2">Availability</label>
                  <select aria-label="Default select example"
                    class="form-select mt-1"
                    style={{ fontSize: "15px", paddingTop: "8px", paddingBottom: "6px" }}
                    onChange={handleUserChange}
                    id="availability"
                    name="availability"
                    value={candidate && candidate.availability}
                  >
                    <option selected>Select availability</option>
                    <option value="Immediate">Immediate</option>
                    <option value="Within 1 Week">Within 1 Week</option>
                    <option value="Within 2 Weeks">Within 2 Weeks</option>
                    <option value="Within 3 Weeks">Within 3 Weeks</option>
                    <option value="Within 4 Weeks">Within 4 Weeks</option>
                    <option value="Within 5 Weeks">Within 5 Weeks</option>
                    <option value="Within 6 Weeks">Within 6 Weeks</option>
                    <option value="Within 7 Weeks">Within 7 Weeks</option>
                    <option value="Within 8 Weeks">Within 8 Weeks</option>
                  </select>
                </form>
                <div class="d-flex gap-4">

                  <button onClick={handleUpdate} className="resume-upload  my-5">
                    {loading ? <Spinner /> : <span>Update</span>}
                  </button>

                </div>
              </div>
            </div>
            {/*EDIT APPLICATION CODE END HERE*/}
          </div>
        </div>
      </div>
    </div>

    <Popup open={showModal} modal closeOnDocumentClick={false}>
      <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
        <div className="popup-containernew">
          <div className="success-message mx-3" style={{marginBottom:'10px'}}>Are you sure you want to delete? This action cannot be undone.</div>
          <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', gap: '10px' ,marginBottom:'15px'}}>
            <button className='employerbuttonbacktodash' onClick={handleCancelDelete}>Cancel</button>
            <button className="employerbuttontobuyinformation" style={{backgroundColor:'#ED5E68'}} onClick={handleConfirmDelete}>OK</button>
          </div>

        </div>
      </div>
    </Popup>
    <ToastContainer
      position="top-center"
      autoClose={10000}
      />
</>)
}

export default AdminCandidates;